<template lang="pug">
  .error-message-section-component
    span.widget-error-title {{ getErrorTitle }}

    .widget-error-message(v-if="getErrorMessage" v-html="getErrorMessage")

    button.button-reset(@click="retry") Попробовать еще раз
</template>

<script>
export default {
  name: 'ErrorMessageSection',
  props: {
    message: {
      type: String,
      default: ''
    },
    errorCode: {
      type: Number,
      default: null
    }
  },
  computed: {
    getErrorTitle () {
      switch (this.errorCode) {
        case 110:
        case 130:
        case 210:
        case 230:
        case 310:
        case 320:
        case 330:
        case 331:
        case 340:
        case 341:
        case 401:
          return `Ошибка ${this.errorCode}`
        default:
          return 'Возникла ошибка'
      }
    },
    getErrorMessage () {
      switch (this.errorCode) {
        case 110:
          return `<span>Запросы с вашего IP-адреса выглядят подозрительно, попробуйте выполнить что-то из этого:</span>
                  <ul>
                    <li>Войдите в браузере в Google-аккаунт</li>
                    <li>Отключите VPN</li>
                    <li>Подключитесь к другой сети</li>
                    <li>Попробуйте из другого браузера</li>
                  </ul>`
        case 130:
          return '<span class="text-center">Вы превысили количество запросов, попробуйте позднее</span>'
        case 230:
          return '<span class="text-center">Канал недоступен, выберите другой</span>'
        case 210:
        case 310:
        case 320:
        case 330:
        case 331:
        case 340:
        case 341:
        case 401:
          return '<span class="text-center">Обратитесь в поддержку и сообщите номер ошибки</span>'
        default:
          return ''
      }
    }
  },
  methods: {
    retry () {
      this.$emit('onRetry')
    }
  }
}
</script>

<style lang="scss" scoped>
  .error-message-section-component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    span {
      display: block;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
    }

    .widget-error-title,
    .widget-error-message {
      display: block;
      margin-bottom: 16px;

      ::v-deep {
        span {
          font-size: 14px;
          line-height: 20px;
        }

        ul {
          padding-left: 21px;
          margin: 16px 0 0 0;

          li {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 4px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        .text-center {
          display: block;
          text-align: center;
        }
      }
    }

    .button-reset {
      color: #009CE5;
      font-size: 14px;
      line-height: 24px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      transition: color 0.2s ease;

      &:hover {
        transition: color 0s ease;
        color: #FF5449;
      }
    }
  }
</style>
