<template lang="pug">
  .idgtl-verify-widget-component-container(:class="{ windowed: getWidgetParams.windowed }")
    .backdrop(v-if="getWidgetParams.windowed")

    .widget-component(ref="widget")
      button.button-widget-close(v-if="getWidgetParams.windowed" @click="closeWidget")
        IconCross

      .preloader(v-if="isLoading" :class="{ opaque: true }")
        IconPreloader

      .success-section(v-if="showSuccessSection")
        IconSuccess
        span Номер подтвержден

      section.error-data-message(v-if="showChannelError")
        span {{ getChannelErrorMessage }}

      ChannelSection(
        v-if="currentStep === 1 && !showChannelError"
        @onChannelSelect="onChannelSelect"
        :widget-params="getWidgetParams"
        :channels="getChannels")

      Captcha(
        v-if="currentStep === 2 && !showChannelError"
        :site-key="getWidgetParams.captchaSiteKey"
        @onVerify="onCaptchaVerify"
        @onRender="onCaptchaRender")

      ConfirmationSection(
        v-if="currentStep === 3 && !showChannelError && !showSuccessSection"
        @onRequestCodeAgain="requestCodeAgain"
        @onCodeInputComplete="sendVerificationCode"
        :widget-params="getWidgetParams"
        :selected-channel="selectedChannel"
        :loading="isConfirmationLoading"
        :confirmation-status="confirmationStatus"
        ref="confirmation")

      ErrorMessageSection(
        v-if="currentStep === 4"
        @onRetry="currentStep = 1"
        :error-code="errorCode"
        :message="errorMessage")
</template>

<script>
import Vue from 'vue'
import {
  getChannelsInfo,
  getVerifierStatus,
  postClientInfo,
  postVerificationCode,
  postClientInfoFromTilda
} from '@/api/requests'
import ChannelSection from '@/components/channel-section/index.vue'
import Captcha from '@/components/captcha/index.vue'
import ConfirmationSection from '@/components/confirmation-section/index.vue'
import ErrorMessageSection from '@/components/error-message-section/index.vue'
import IconPreloader from '@/components/icons/IconPreloader.vue'
import IconSuccess from '@/components/icons/IconSuccess.vue'
import IconCross from '@/components/icons/IconCross.vue'

export default {
  name: 'WidgetComponent',
  components: {
    ErrorMessageSection,
    IconSuccess,
    ConfirmationSection,
    Captcha,
    ChannelSection,
    IconPreloader,
    IconCross
  },
  props: {
    widgetId: {
      type: String,
      default: ''
    },
    destination: {
      type: String,
      default: ''
    },
    checkCallback: {
      type: Function,
      default: null
    },
    successCallback: {
      type: Function,
      default: null
    },
    captchaSiteKey: {
      type: String,
      default: 'b407bddc-f768-4697-8fa4-43c9d003af75'
    },
    apiKey: {
      type: String,
      default: ''
    },
    _customBaseUrl: {
      type: String,
      default: ''
    },
    windowed: Boolean
  },
  data () {
    return {
      isLoading: true,
      showConfirmationCodeSection: false,
      isConfirmationLoading: false,
      hasSendError: false,
      showSuccessSection: false,
      hasGetChannelsError: false,
      waitTimeout: null,
      captchaUuid: null,
      widgetType: null,
      currentStep: 1,
      currentAttempt: 1,
      maxAttempts: 0,
      captchaType: 'HCAPTCHA',
      confirmationStatus: '',
      errorMessage: '',
      errorCode: null,
      draftKey: '',
      channels: [
        { name: 'VK', value: 'VK', icon: 'IconVK' },
        { name: 'WhatsApp', value: 'WHATSAPP', icon: 'IconWhatsApp' },
        { name: 'WhatsApp', value: 'WEASY', icon: 'IconWhatsApp' },
        { name: 'SMS', value: 'SMS', icon: 'IconSMS' },
        { name: 'Код в звонке', value: 'VOICECODE', icon: 'IconConfirmationCall' },
        { name: 'Код в звонке', value: 'FLASHCALL', icon: 'IconConfirmationCall' },
        { name: 'Viber', value: 'VIBER', icon: 'IconViber' }
      ],
      selectedChannel: {},
      availableChannels: [],
      phoneRegex: /^\+?\d+$/,
      recaptchaLink: 'https://www.google.com/recaptcha/api.js?render='
    }
  },
  computed: {
    getWidgetParams () {
      return {
        destination: this.$widgetOptions?.destination || this.destination,
        widgetId: this.$widgetOptions?.widgetId || this.widgetId,
        captchaSiteKey: this.$widgetOptions?.captchaSiteKey || this.captchaSiteKey,
        apiKey: this.$widgetOptions?.apiKey || this.apiKey,
        windowed: this.$widgetOptions?.windowed || this.windowed
      }
    },
    getChannelErrorMessage () {
      const destination = this.getWidgetParams.destination
      const isDestinationValid = this.phoneRegex.test(destination)

      if (this.hasGetChannelsError) {
        return 'Возникла ошибка при попытке получить список доступных каналов'
      }

      if (!this.getWidgetParams.destination) {
        console.warn('[verify-widget]: Необходимо указать параметр "destination"')
        return 'Не указан номер телефона'
      }

      if (!this.getWidgetParams.captchaSiteKey) {
        console.warn('[verify-widget]: Не указан секретный ключ капчи')
        return 'Не указан секретный ключ капчи'
      }

      if (!isDestinationValid) {
        console.warn('[verify-widget]: Указан неверный формат номера телефона')
        return 'Указан неверный формат номера телефона'
      }

      if (!this.getWidgetParams.destination && !this.getWidgetParams.widgetId) {
        console.warn('[verify-widget]: Необходимо указать параметр "widgetId" и "destination"')
        return 'Не указан номер телефона и ID виджета'
      }

      if (!this.getWidgetParams.widgetId) {
        console.warn('[verify-widget]: Необходимо указать параметр "widgetId"')
        return 'Не указан ID виджета'
      }

      if (!this.getCheckCallbackFunction) {
        console.warn('[verify-widget]: Не указан вызов проверки кода')
        return 'Не указан вызов проверки кода'
      }

      console.warn('[verify-widget]: Неизвестная ошибка')
      return 'Неизвестная ошибка'
    },
    showChannelError () {
      const destination = this.getWidgetParams.destination
      const isCaptchaKeyValid = !!this.getWidgetParams.captchaSiteKey
      const isDestinationValid = this.phoneRegex.test(destination)

      return !destination ||
          !this.getWidgetParams.widgetId ||
          (!this.getCheckCallbackFunction && this.widgetType !== 'TILDA') ||
          this.hasGetChannelsError ||
          !isDestinationValid ||
          !isCaptchaKeyValid
    },
    getCheckCallbackFunction () {
      if (!this.$widgetCheckCallback && !this.checkCallback) return null

      return this.$widgetCheckCallback || this.checkCallback
    },
    getSuccessCallbackFunction () {
      if (!this.$widgetOnSuccessCallback && !this.successCallback) return null

      return this.$widgetOnSuccessCallback || this.successCallback
    },
    getChannels () {
      const channels = []

      this.availableChannels.forEach(channel => {
        const currentChannel = this.channels.find(item => item.value === channel.channelType)
        if (currentChannel && channel.steps && channel.steps.includes(this.currentAttempt)) {
          channels.push({
            ...currentChannel,
            codeLength: channel.codeLength,
            steps: channel.steps
          })
        }
      })

      return channels
    }
  },
  methods: {
    closeWidget () {
      if (this.$parent.removeWidget) {
        if (this.$tildaFormID) {
          const form = document.querySelector(`${this.$tildaFormID} form`)
          if (!form) return

          const inputTel = form.querySelector('input[type="tel"]')
          const formPhoneMask = form.querySelector('.t-input-phonemask__select')
          const formButton = form.querySelector('button')

          if (formPhoneMask) formPhoneMask.style.pointerEvents = ''
          if (inputTel) inputTel.removeAttribute('readonly')
          if (formButton) formButton.disabled = false
        }

        this.$parent.removeWidget()
      }

      this.$emit('onClose')
    },
    requestCodeAgain () {
      if (this.maxAttempts > this.currentAttempt) this.currentAttempt += 1

      const currentAttemptChannels = this.availableChannels.filter(channel => {
        return channel.steps.find(step => step === this.currentAttempt)
      })
      const hasOnlyOneCurrentStepChannel = currentAttemptChannels.length === 1

      if (this.availableChannels.length === 1) {
        this.switchToCaptchaScreen()
      } else if (hasOnlyOneCurrentStepChannel) {
        const selectedChannel = currentAttemptChannels[0]
        const selectedChanelInfo = this.channels.find(channel => channel.value === selectedChannel.channelType)

        this.isLoading = false

        this.selectedChannel = {
          codeLength: selectedChannel.codeLength,
          steps: selectedChannel.steps,
          value: selectedChannel.channelType,
          icon: selectedChanelInfo.icon,
          name: selectedChanelInfo.name
        }
        this.switchToCaptchaScreen()
      } else {
        this.currentStep = 1
        this.isLoading = false
      }

      this.showSuccessSection = false
      this.draftKey = ''
      this.confirmationStatus = ''
    },
    onCaptchaRender () {
      this.isLoading = false
    },
    onChannelSelect (channel) {
      this.selectedChannel = channel
      this.isLoading = true
      this.switchToCaptchaScreen()
    },
    onCaptchaVerify (response) {
      this.captchaUuid = response
      this.sendClientInfo()
    },
    async getAvailableChannels () {
      if (!this.getWidgetParams.widgetId) {
        this.isLoading = false
        console.warn('[verify-widget]: Необходимо указать параметр "widgetId"')
        return
      }

      this.isLoading = true

      try {
        const { channelsInfo, steps, captchaType, type } = await getChannelsInfo(this.getWidgetParams.widgetId)
        this.widgetType = type
        this.maxAttempts = steps

        if (captchaType) {
          this.captchaType = captchaType
        }

        if (this.widgetType === 'TILDA' && !this.getWidgetParams.apiKey) {
          this.errorCode = 330
          this.isLoading = false
          this.currentStep = 4
          console.warn('[verify-widget]: Необходимо указать api-ключ Tilda')
          return
        }

        if (this.captchaType === 'RECAPTCHA') {
          try {
            if (!window.isDirectVerifyWidgetCaptchaLoaded) {
              await this.loadRecaptcha()
            }
          } catch (err) {
            console.warn('Error', err)
          }
        }

        if (channelsInfo.length) {
          this.availableChannels = channelsInfo
        }

        const hasOnlyOneFirstChannel = channelsInfo.filter(channel => channel.steps.includes(1)).length === 1

        if (hasOnlyOneFirstChannel) {
          const selectedChannel = channelsInfo.find(channel => channel.steps.includes(1))
          const selectedChanelInfo = this.channels.find(channel => channel.value === selectedChannel.channelType)

          this.selectedChannel = {
            codeLength: selectedChannel.codeLength,
            steps: selectedChannel.steps,
            value: selectedChannel.channelType,
            icon: selectedChanelInfo.icon,
            name: selectedChanelInfo.name
          }
          this.switchToCaptchaScreen()
        } else {
          this.isLoading = false
        }
      } catch {
        console.warn('[verify-widget] Возникла ошибка при попытке получить список доступных каналов')
        this.hasGetChannelsError = true
        this.isLoading = false
      }
    },
    loadRecaptcha () {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script')

        script.setAttribute('src', this.recaptchaLink + this.getWidgetParams.captchaSiteKey)
        script.setAttribute('async', '')
        this.$refs.widget.appendChild(script)
        script.onload = () => {
          window.isDirectVerifyWidgetCaptchaLoaded = true
          return resolve()
        }
        script.onerror = () => {
          window.isDirectVerifyWidgetCaptchaLoaded = false
          this.errorCode = 341
          this.currentStep = 4
          reject(new Error('[verify-widget] Ошибка загрузки reCaptcha'))
        }
      })
    },
    async getRecaptchaToken () {
      return new Promise((resolve, reject) => {
        window.grecaptcha.ready(async () => {
          try {
            const token = await window.grecaptcha.execute(this.getWidgetParams.captchaSiteKey, { action: 'public_confirmation_code' })
            return resolve(token)
          } catch (err) {
            console.warn(err)
            reject(new Error('[verify-widget] Ошибка 341: обратитесь в поддержку и сообщите номер ошибки'))
          }
        })
      })
    },
    async switchToCaptchaScreen () {
      this.isLoading = true
      switch (this.captchaType) {
        case 'HCAPTCHA':
          this.currentStep = 2
          this.isLoading = false
          break
        case 'RECAPTCHA':
          try {
            const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
            await sleep(600)
            this.captchaUuid = await this.getRecaptchaToken()
          } catch (err) {
            console.warn(err)
            this.errorCode = 341
            this.currentStep = 4
            this.isLoading = false
            return
          }
          this.sendClientInfo()
          break
      }
    },
    processErrorCode (errorCode) {
      switch (errorCode) {
        case 400:
        case 4000:
          console.warn('[verify-widget] Ошибка 320: обратитесь в поддержку и сообщите номер ошибки')
          this.errorCode = 320
          break
        case 4012:
          console.warn('[verify-widget] Ошибка 330: обратитесь в поддержку и сообщите номер ошибки')
          this.errorCode = 330
          break
        case 402:
          console.warn('[verify-widget] Ошибка 210: обратитесь в поддержку и сообщите номер ошибки')
          this.errorCode = 210
          break
        case 4030:
          console.warn('[verify-widget] Ошибка 331: обратитесь в поддержку и сообщите номер ошибки')
          this.errorCode = 331
          break
        case 4031:
          console.warn('[verify-widget] Ошибка 340: обратитесь в поддержку и сообщите номер ошибки')
          this.errorCode = 340
          break
        case 404:
          console.warn('[verify-widget] Ошибка 404: обратитесь в поддержку и сообщите номер ошибки')
          this.errorCode = 310
          break
        case 4223:
          console.warn('[verify-widget] Ошибка 110: обратитесь в поддержку и сообщите номер ошибки')
          this.errorCode = 110
          break
        case 429:
        case 4290:
          console.warn('[verify-widget] Ошибка 130: вы превысили количество запросов, попробуйте позднее')
          this.errorCode = 130
          break
        case 4221:
          console.warn('[verify-widget] Ошибка 230: канал недоступен, выберите другой')
          this.errorCode = 230
          break
        default:
          console.warn('[verify-widget] Возникла ошибка')
          this.errorCode = null
          break
      }

      this.currentStep = 4
    },
    async sendClientInfo () {
      if (!this.getWidgetParams.destination) {
        console.warn('[verify-widget]: Необходимо указать параметр "destination"')
        return
      }

      if (!this.getWidgetParams.widgetId) {
        console.warn('[verify-widget]: Необходимо указать параметр "widgetId"')
        return
      }

      this.isLoading = true

      const data = {
        destination: this.getWidgetParams.destination,
        channelType: this.selectedChannel.value,
        widgetId: this.getWidgetParams.widgetId,
        captchaResponse: this.captchaUuid
      }

      try {
        const { key } = await postClientInfo(data)
        this.draftKey = key

        switch (this.widgetType) {
          case 'TILDA':
            await this.sendTildaWidgetData(key)
            break
          default:
            await this.sendBasicWidgetData(key)
            break
        }

        await this.loadVerifierStatus()
      } catch (err) {
        const error = err.error || err.response

        if (error && error.code) {
          this.processErrorCode(error.code)
        }

        if (error && error.status) {
          this.processErrorCode(error.status)
        }
        this.isLoading = false
      }
    },
    async sendBasicWidgetData (key) {
      try {
        const checkCallbackFunc = this.getCheckCallbackFunction.bind(key)
        await this.sleep(2000)

        const res = await checkCallbackFunc(key)

        if (res && !res.ok) {
          const resJSON = await res.json()
          const status = resJSON?.error?.code || res.status || null

          this.processErrorCode(status)
          console.warn('[verify-widget] Возникла ошибка при попытке отправки данных клиента')
          this.isLoading = false
          throw new Error()
        }
      } catch (err) {
        console.warn(err)
        throw err
      }
    },
    async sendTildaWidgetData (key) {
      await this.sleep(2000)

      const data = {
        key
      }

      const options = {
        headers: { Authorization: `Basic ${this.getWidgetParams.apiKey}` }
      }

      // eslint-disable-next-line no-useless-catch
      try {
        await postClientInfoFromTilda(data, options)
      } catch (err) {
        throw err
      }
    },
    async loadVerifierStatus () {
      try {
        const { status } = await getVerifierStatus(this.draftKey)
        this.isLoading = false
        switch (status) {
          case 'SENT':
            this.currentStep = 3
            await this.$nextTick()
            this.$refs.confirmation.resetCountdown()
            break
          case 'ERROR':
          case 'CREATED':
            this.loadVerifierStatus()
            break
        }
      } catch (err) {
        console.warn('[verify-widget] Возникла ошибка при попытке получить статус верификации')
      } finally {
        this.isLoading = false
      }
    },
    async sendVerificationCode (code) {
      this.isConfirmationLoading = true

      const data = {
        key: this.draftKey,
        code
      }

      try {
        const { status } = await postVerificationCode(data)
        this.confirmationStatus = status
        switch (status) {
          case 'CONFIRMED':
            this.showSuccessSection = true

            if (this.getWidgetParams.windowed) {
              setTimeout(() => {
                if (this.$parent.removeWidget) {
                  this.$parent.removeWidget()
                }

                this.$emit('onClose')

                if (this.$tildaFormID) {
                  const form = document.querySelector(`${this.$tildaFormID} form`)
                  if (!form) return

                  const inputTel = form.querySelector('input[type="tel"]')
                  const tildaFormSubmitButton = form.querySelector('button[type="submit"]')
                  const widgetFormButton = form.querySelector('.idirect-widget-tilda-button')

                  if (widgetFormButton) widgetFormButton.remove()

                  if (tildaFormSubmitButton) {
                    tildaFormSubmitButton.style.display = ''
                    tildaFormSubmitButton.removeAttribute('disabled')
                    this.$nextTick(() => {
                      inputTel.removeAttribute('readonly')
                      tildaFormSubmitButton.click()
                      inputTel.setAttribute('readonly', 'true')
                    })
                  }
                }
              }, 500)
            }

            if (this.getSuccessCallbackFunction) {
              const successCallbackFunc = this.getSuccessCallbackFunction.bind()
              successCallbackFunc()
            }

            this.$emit('onSuccess')
            break
          case 'WRONG_CODE':
            this.$refs.confirmation.resetCode()
            break
        }
      } catch (err) {
        console.warn('Error', err)
        console.warn('[verify-widget] Возникла ошибка при отправке кода подтверждения')
      } finally {
        this.isConfirmationLoading = false
      }
    },
    sleep (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    }
  },
  created () {
    if (this.$widgetOptions?._customBaseUrl || this._customBaseUrl) {
      Vue.prototype.$_customBaseUrl = this.$widgetOptions?._customBaseUrl || this._customBaseUrl
    }
  },
  mounted () {
    this.getAvailableChannels()
  }
}
</script>

<style>
  @import url('https://cdn.direct.i-dgtl.ru/2fa-widget/static/verify-widget-fonts.css');
</style>

<style lang="scss" scoped>
  .idgtl-verify-widget-component-container {
    width: 400px;

    &.windowed {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding: 20px 0;
      z-index: 2147483647;
      overflow-y: scroll;

      .backdrop {
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba(#094DA2, 0.5);
      }

      .widget-component {
        z-index: 2;
      }
    }

    .backdrop {
      display: none;
    }

    .widget-component {
      position: relative;
      display: flex;
      width: 400px;
      min-height: 160px;
      padding: 24px 48px;
      background: $color-white;
      border-radius: 8px;

      .button-widget-close {
        position: absolute;
        width: 24px;
        height: 24px;
        right: 10px;
        top: 10px;
        cursor: pointer;
        color: $color-gray-60;

        &:hover {
          color: $color-red-100;
        }
      }

      .preloader {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        margin: auto;
        background-color: rgba($color-white, 0.5);
        border-radius: 8px;
        z-index: 2;

        &.opaque {
          background-color: $color-white;
        }

        svg {
          width: 88px;
          height: 88px;
        }
      }

      .success-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        margin: auto;
        background-color: $color-white;
        border-radius: 8px;
        z-index: 2;

        svg {
          width: 56px;
          height: 56px;
        }

        span {
          display: block;
          margin-top: 10px;
          font-size: 18px;
          font-weight: 400;
          line-height: 26px;
        }
      }

      .error-data-message {
        display: flex;
        align-self: center;
        width: 100%;

        span {
          display: block;
          width: 100%;
          padding: 10px 20px;
          font-size: 14px;
          line-height: 22px;
          font-weight: 300;
          background-color: #FEF0EF;
        }
      }

      @media (max-width: 479px) {
        width: 100%;
        padding: 32px 16px;
      }
    }

    @media (max-width: 767px) {
      width: 100%;

      .widget-component {
        width: 100%;
        padding: 20px;
      }
    }
  }
</style>
